
import { defineComponent, PropType } from "vue";
import Typed from 'typed.js';

export default defineComponent({
    name: "MessageAnimated",
    emits: ["stringtyped"],
    props: {
        strings: {
            type: Array as PropType<string[]>,
            required: true
        },
        idPrefix: {
            type: String
        },
        typeSpeed: {
            type: Number,
            default: 60
        },
        delayStart: {
            type: Number,
            default: 100
        },
        delayBetweenStrings: {
            type: Number,
            default: 1000
        }
    },
    methods: {
        addMessage() {
            if (this.messageIndex >= this.strings.length) {
                return
            }

            const msgText = this.strings[this.messageIndex]
            const elementId = `msg-${this.idPrefix}${this.messageIndex}`
            new Typed(`#${elementId}`, {
                    strings: [msgText],
                    showCursor: false,
                    startDelay: this.delayBetweenStrings,
                    typeSpeed: this.typeSpeed,
                    onComplete: () => {
                        this.stringTyped()
                        this.addMessage()
                    }
            })
            this.messageIndex++
        },
        stringTyped() {
            this.$emit("stringtyped", this.messageIndex)
        }
    },
    data() {
        return {
            messageIndex: 0,
            messages: [] as Array<{
                id: string,
                text: string
            }>
        }
    },
    async mounted() {
        for (let i = 0; i < this.strings.length; i++) {
            this.messages.push({
                id: `msg-${this.idPrefix}${i}`,
                text: this.strings[i]
            })
        }
        await new Promise(r => setTimeout(r, this.delayStart));
        this.addMessage()
    }
});
