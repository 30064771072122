import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "choice-block" }
const _hoisted_2 = ["id", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.choices, (choice, i) => {
      return (_openBlock(), _createElementBlock("button", {
        type: "button",
        class: "choice",
        key: i,
        id: `choice-${i}`,
        ref_for: true,
        ref: `choice${i}`,
        onClick: ($event: any) => (_ctx.onClick(i)),
        onMouseover: _cache[0] || (_cache[0] = ($event: any) => ($event.target.focus()))
      }, _toDisplayString(choice), 41, _hoisted_2))
    }), 128))
  ]))
}