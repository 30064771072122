
import { defineComponent } from "vue";
import MessageAnimated from "@/components/message-animated.vue";
import MessageInputChoices from "@/components/message-input-choices.vue";
import router from "@/router";

export default defineComponent({
    components: { MessageAnimated, MessageInputChoices },
    name: "Hello",
    methods: {
        async checkMessageStatus(index: number) {
            if (index >= this.messages.length) {
                await new Promise(r => setTimeout(r, 1000));
                this.messageDone = true;
            }
        },
        async checkChoice(button: HTMLButtonElement) {
          if (button.textContent === "yes") {
            this.allow(button);
          } else {
            this.block(button);
          }
        },
        async allow(button: HTMLButtonElement) {
          const addText = "okay.";
          button.style.backgroundColor = "#fd9";
          /*
          const leftPos = button.getBoundingClientRect().left
          const topPos = button.getBoundingClientRect().top
          button.style.position = "fixed";
          button.style.top = `${topPos}px`;
          button.style.left = `${leftPos}px`;
          */
          await new Promise(r => setTimeout(r, 1000));
          for (let i = 0; i < addText.length; i++) {
            await new Promise(r => setTimeout(r, 60));
            button.textContent += addText.charAt(i);
          }
          await new Promise(r => setTimeout(r, 2000));
          router.push("megan");
        },
        async block(button: HTMLButtonElement) {
          button.style.backgroundColor = "#fd9";
          await new Promise(r => setTimeout(r, 2000));
          window.location.href = "https://www.google.com/search?q=how+to+mind+my+own+business";
        }
    },
    data() {
        return {
            messages: ["hello.", "&nbsp;are you <span style='background-color:orange;'>megan</span>?"],
            choices: ["yes", "no"],
            messageDone: false
        }
    }
});
