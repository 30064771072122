import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (msg) => {
    return (_openBlock(), _createElementBlock("span", {
      key: msg.id,
      id: msg.id
    }, null, 8, _hoisted_1))
  }), 128))
}