<template>
  <router-view id="content" />
  <footer>
    <svg>
      <filter id="chicken">
        <feColorMatrix type="matrix" values="
          1 0 0 0 0
          0.4 1 0 0 0
          0 0 1 0 0
          0 0 0 1 0" />
      </filter>
    </svg>
    made with <span class="heart">&#x2764;</span><span class="chicken">&#x1F414;</span>
  </footer>
</template>

<style lang="scss">
#app {
  font-family: 'Consolas', 'Courier New', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  height: 100%;
  display: flex;
  flex-direction: column;
}

#content {
  flex: 1 0 auto;
  margin: 2rem;
}

footer {
  background: #daffff;
  color: #014a88;
  flex-shrink: 0;
  padding: 30px;
  text-align: center;
  font-size: calc(12px + 0.8vh);
}

svg {
  display: none;
}

.heart {
  color: orange;
}

.chicken {
  -webkit-filter: url(#chicken);
  filter: url(#chicken);
}
</style>
