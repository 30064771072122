import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Hello from "../views/hello.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "hello",
    component: Hello,
  },
  {
    path: "/megan",
    name: "megan",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "megan" */ "../views/megan.vue"),
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
